import React from 'react'
import Footer from '../footer/Footer'
import Nav from '../nav/Nav'
import './impressum.css'
import '../../index.css'
import logo from '../../assets/mergim-rexha-logo-white.svg'


const Impressum = () => {
  return (
    <>
      <section id='impressum'>
        <div className="container">
          <a href="https://mergim-rexha.de"><img href="https://mergim-rexha.de" src={logo} className="logo" alt="Logo Mergim Rexha" /></a>
          <h1>Impressum</h1>
          <h2 className="grey">Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
          <p>Mergim Rexha<br />
            Am Krumbach 22<br />
            40822 Mettmann</p><br />

          <h2 className="grey">Kontakt</h2>
          <p>Telefon: <a href="tel:+491774950544">+49 (0) 1774 950 544</a> <br />
            E-Mail: <a href="mailto:kontakt@mergim-rexha.de?subject=Allgemeine%20Fragen">kontakt@mergim-rexha.de</a></p><br />

          <h2 className="grey">EU-Streitschlichtung</h2>
          <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p><br />

          <h2 className="grey">Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
          <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

          <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>

        </div>
      </section>

      <Nav />

      <footer><Footer /></footer>

    </>

  )
}

export default Impressum